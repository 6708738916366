<template>
  <div class="phone-number">
    <label class="phone-number__label button-small">Телефон</label>
    <div class="phone-number__input-wrapper">
      <base-select-universal
        class="phone-number__input-select"
        :value="prefix"
        :option-list="countryList"
        @change="unionPhonePrefix"
      ></base-select-universal>
      <base-input class="phone-number__input" :value="phoneNumber" type="tel" @input="unionPhone"></base-input>
    </div>
    <div v-if="incorrectPhone" class="phone-number__error body-4">
      Некорректно введен номер телефона для этого кода страны
    </div>
  </div>
</template>

<script lang="js">
import BaseInput from "@/components/BaseInput";
import BaseSelectUniversal from "@/components/BaseSelectUniversal";
import { ref, reactive, defineComponent } from "vue";

export default defineComponent({
  name: "BasePhoneNumber",
  components: { BaseSelectUniversal, BaseInput },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    }
  },
  setup(props, { emit }) {
    const phoneNumber = ref(props.phoneNumber.slice(2, props.phoneNumber.length));
    const incorrectPhone = ref(false);
    const countryList = reactive([
      {
        id: 1,
        text: "Россия (+7)",
        icon: "Russia.svg",
        value: "+7",
      },
      {
        id: 2,
        text: "Кыргызстан (+996)",
        icon: "Kyrgyzstan.svg",
        value: "+996",
      },
    ]);
    const prefix = ref(countryList[0].value);
    const checkCorrectPhoneNumber = () => {
      if (prefix.value === "+996") {
        incorrectPhone.value = !/[0-9]{9}$/.test(phoneNumber.value);
      } else {
        incorrectPhone.value = !/^9[0-9]{9}$|^\(\d{3}\)\d{3}-\d{2}-\d{2}$/.test(phoneNumber.value);
      }
    }

    const unionPhone = v => {
      phoneNumber.value = v.target.value
      checkCorrectPhoneNumber();
      emit("getPhone", {
        phoneFull: `${prefix.value}${phoneNumber.value}`,
        phoneWithoutPrefix: `${phoneNumber.value}`,
        error: incorrectPhone.value
      });
    }
    const unionPhonePrefix = v => {
      prefix.value = v.target.value
      checkCorrectPhoneNumber();
      emit("getPhone", {
        phoneFull: `${prefix.value}${phoneNumber.value}`,
        phoneWithoutPrefix: `${phoneNumber.value},`,
        error: incorrectPhone.value
      });
    }


    return {
      prefix,
      phoneNumber,
      countryList,
      incorrectPhone,
      unionPhone,
      unionPhonePrefix
    };
  },
});
</script>

<style scoped lang="scss">
.phone-number {
  width: 100%;
  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  &__input-select {
    width: 50%;
  }
  &__input {
    width: 100%;
  }
  &__label {
    color: var(--color-neutral-600);
  }
  &__error {
    color: var(--Red-Dark-main, #ad0015);
  }
}
</style>
