var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showFlag)?_c('div',{staticClass:"people-window"},[_c('div',{staticClass:"people-window__overlay",on:{"click":_setup.hideModal}}),_c('div',{staticClass:"people-window__content"},[_c('div',{staticClass:"people-window__title py-4 h4"},[_vm._v("Карточка сотрудника")]),_c('div',{staticClass:"people-window__person-main-info px-4 pb-4"},[_c('div',{staticClass:"people-window__person-main-info-photo"},[_c(_setup.BaseAvatar,{attrs:{"info":_setup.person,"size":"80","screen-size-dependency":"","name-color":"var(--color-neutral-800)","flex-direction":"column","text-align":"center","can-change-photo":"","open-in-full-screen":""},on:{"openModal":function($event){_setup.openCropper = !_setup.openCropper}}})],1),(_setup.openCropper)?_c('div',{staticClass:"people-window__photo-change-wrapper"},[(_setup.image.src || _setup.person.photo)?_c('div',{staticClass:"people-window__cropper-wrapper"},[_c(_setup.Cropper,{staticClass:"flexR center cropper",attrs:{"src":_setup.image.src === null ? _setup.PHOTO_ROUTE + _setup.person.photo : _setup.image.src,"default-size":_setup.defaultSize},on:{"change":_setup.cropPhoto}})],1):_vm._e(),_c(_setup.BaseInput,{ref:"inputFile",staticClass:"people-window__input-file mt-4",attrs:{"type":"file","name":"photo"},on:{"change":_setup.onFileChange}}),(_setup.wrongImageSize)?_c('span',[_vm._v(" Изображение слишком большое, максимальный размер изображения 10Мб ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"people-window__form"},[_c(_setup.BaseInput,{attrs:{"label":"Ник","value":_setup.person.nickname},on:{"input":v => (_setup.person.nickname = v.target.value)}}),_c(_setup.BaseSelect,{attrs:{"label":"Статус","option-list":[
          { id: 'work', nickname: 'В игре' },
          { id: 'fired', nickname: 'Уволен' },
          { id: 'pause', nickname: 'На паузе' },
          { id: 'workpause', nickname: 'В игре и на паузе' },
        ],"value":_setup.person.status},on:{"change":v => (_setup.person.status = v.target.value)}}),_c(_setup.BaseSelect,{attrs:{"label":"Должность","option-list":_setup.jobList,"value":_setup.person.id_job},on:{"change":v => (_setup.person.id_job = v.target.value)}}),_c(_setup.BaseInput,{attrs:{"label":"Доп. должность","value":_setup.person.job_extra},on:{"input":v => (_setup.person.job_extra = v.target.value)}}),_c(_setup.BaseDivider),_c(_setup.BaseInput,{attrs:{"label":"Фамилия","value":_setup.person.surname},on:{"input":v => (_setup.person.surname = v.target.value)}}),_c(_setup.BaseInput,{attrs:{"label":"Имя","value":_setup.person.name},on:{"input":v => (_setup.person.name = v.target.value)}}),_c(_setup.BaseInput,{attrs:{"label":"Отчетсво","value":_setup.person.patronymic},on:{"input":v => (_setup.person.patronymic = v.target.value)}}),_c(_setup.BaseSelect,{attrs:{"label":"Пол","option-list":[
          { id: 'm', nickname: 'Мужской' },
          { id: 'f', nickname: 'Женский' },
        ],"value":_setup.person.gender},on:{"change":v => (_setup.person.gender = v.target.value)}}),_c(_setup.BaseSelect,{attrs:{"label":"Курение","option-list":[
          { id: 'smoke', nickname: 'Курит' },
          { id: 'agreement', nickname: 'Соглашение (премия)' },
          { id: 'none', nickname: 'Нет' },
          { id: 'other', nickname: 'Другое' },
        ],"value":_setup.person.smoke_status},on:{"change":v => (_setup.person.smoke_status = v.target.value)}}),_c(_setup.BaseDatePicker,{attrs:{"type":"date","label":"День рождения","value":_setup.person.birth},on:{"change":v => (_setup.person.birth = v.target.value)}}),_c(_setup.BaseDatePicker,{attrs:{"type":"date","label":"Дата начала работы","value":_setup.person.date_hire},on:{"change":v => (_setup.person.date_hire = v.target.value)}}),_c(_setup.BasePhoneNumber,{attrs:{"phone-number":_setup.person.phone},on:{"getPhone":_setup.setPhone}}),_c(_setup.BaseInput,{attrs:{"label":"Телеграмм","value":_setup.person.telegram},on:{"input":v => (_setup.person.telegram = v.target.value)}}),_c(_setup.BaseSelect,{attrs:{"label":"Банк","option-list":[
          { id: 'Альфа', nickname: 'Альфа' },
          { id: 'Сбер', nickname: 'Сбер' },
          { id: 'Тинек', nickname: 'Тинек' },
          { id: 'Другое', nickname: 'Другое' },
        ],"value":_setup.person.bank},on:{"change":v => (_setup.person.bank = v.target.value)}}),_c(_setup.BaseDivider),_c(_setup.BaseMultiselect,{attrs:{"values":_setup.person.roles,"options":[
          { text: 'User', value: 'user' },
          { text: 'Admin', value: 'admin' },
          { text: 'HR Admin', value: 'hradmin' },
          { text: 'Point Admin', value: 'pointadmin' },
        ]},on:{"select":v => (_setup.person.roles = v)}}),_c(_setup.BaseInput,{attrs:{"label":"Логин","value":_setup.person.login},on:{"input":v => (_setup.person.login = v.target.value)}}),_c(_setup.BaseInput,{attrs:{"label":"Пароль","value":_setup.person.pass},on:{"input":v => (_setup.person.pass = v.target.value)}}),(_vm.personIn)?_c(_setup.BaseButton,{staticClass:"people-window__delete __red",attrs:{"is-new":"","primary":"","small":""},on:{"click":_setup.deleteUser}},[_vm._v(" Удалить сотрудника ")]):_vm._e()],1),_c('div',{staticClass:"people-window__action-wrapper"},[_c('div',{staticClass:"people-window__actions px-4"},[_c(_setup.BaseButton,{attrs:{"is-new":"","tertiary":"","small":""},on:{"click":_setup.hideModal}},[_vm._v("Отмена")]),_c(_setup.BaseButton,{attrs:{"is-new":"","primary":"","small":""},on:{"click":_setup.onSubmit}},[_vm._v("Применить")])],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }